import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ChatApp from "../../Assets/Projects/chatapp.png";
import ApnaAi from "../../Assets/Projects/apnaai.png";
import YTDataScrapper from "../../Assets/Projects/ytscrapper.png";
import FunCalc from "../../Assets/Projects/funcalc.png";
import EmployeeManagement from "../../Assets/Projects/empmangement.png";
import WeatherWebApp from "../../Assets/Projects/weatherapp.png";
import CurrencyConverter from "../../Assets/Projects/currencyconverter.png";
import Inventory from "../../Assets/Projects/inventory.png";
import apnafitness from "../../Assets/Projects/apnafitness.png";
import krishimitra from "../../Assets/Projects/krishimitra.png";
import drawandcalculate from "../../Assets/Projects/drawandcalculate.png";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={drawandcalculate}
              isBlog={false}
              title="Draw and Calculate"
              description="Draw and Calculate is an animated calculator where users can draw math expressions and get instant results. Built with React JS, Express JS, and Python, it uses the Gemini API for handwriting recognition and solving equations. 🚀"
              
              deployedLink="https://draw-and-calculate.vercel.app/" // Add your deployed link here
            />
          </Col>  
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={krishimitra}
              isBlog={false}
              title="Krishi Mitra: AI-powered Agriculture Web Application"
              description="Krishi Mitra is a web application integrates AI/ML models for accurate predictions and recommendations and APIs for plant disease detection, AI chatbot functionality, and weather forecasts. This project aims to bridge the gap between technology and agriculture, helping farmers optimize their practices for better crop management and higher yields."
              ghlink="https://github.com/Aryesh404/Krishi-Mitra-AI-powered-Agriculture-Web-Application"
              deployedLink="https://krishi-mitra-eight.vercel.app/" // Add your deployed link here
            />
          </Col>      
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={apnafitness}
              isBlog={false}
              title="Apna Fitness: Your Personal Fitness Tracker"
              description="Apna Fitness is a web application built using Spring Boot, React JS, and MySQL that helps users track their exercise routines. Users can register, log in, and save their fitness data securely."
              ghlink="https://github.com/Aryesh404/Apna-Fitness"
              // deployedLink="https://apna-fitness-65932bb9c74a.herokuapp.com/" // Add your deployed link here
            />
          </Col>         
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Inventory}
              isBlog={false}
              title="Inventory Management System"
              description="Inventory Management System: This web-based application, developed using Spring Boot, Thymeleaf, and MongoDB, allows users to manage and track inventory efficiently. Features include user authentication for personalized access, CRUD operations for inventory items, and a dashboard for viewing inventory status. Users can save their inventory data separately and access only their own data after logging in a safe and securely."
              ghlink="https://github.com/Aryesh404/EazyByts-Infotech-2nd"
              // deployedLink="https://inventory-manage-239b682eaffc.herokuapp.com/" // Add your deployed link here
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ChatApp}
              isBlog={false}
              title="Apna Chat: Connect Instantly, Anytime Anywhere"
              description="Apna Chat is a real-time chat application developed using Spring Boot and React JS. It supports both group and personal messaging, providing users with a seamless and interactive chat experience.The app uses WebSockets for instant communication, and it is designed to be scalable and secure for future enhancements."
              ghlink="https://github.com/Aryesh404/Apna-Chat-App"
              // deployedLink="https://apnachatapp.vercel.app/" // Add your deployed link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ApnaAi}
              isBlog={false}
              title="Apna AI: AI that hears, sees and responds"
              description="Apna AI is a chatbot developed with React JS and powered by the Gemini API. It supports voice input/output and allows image uploads, enhancing its interactive and responsive capabilities. Apna AI provides quick, accurate, and context-aware responses to user queries."
              ghlink="https://github.com/Aryesh404/Apna-AI"
              deployedLink="https://apnaai.vercel.app" // Add your deployed link here
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={YTDataScrapper}
              isBlog={false}
              title="YT Data Scrapper"
              description="YT Data Scrapper is a web-based application that allows users to extract key information from any YouTube video by simply pasting the video link. The extracted data includes the video's title, thumbnail, tags, and description."
              ghlink="https://github.com/Aryesh404/YT-Data-Scrapper"
              // deployedLink="https://yt-scrapper-ecf597814170.herokuapp.com/"
              // Add your deployed link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={FunCalc}
              isBlog={false}
              title="FunCalc: The Animated Scientific Calculator"
              description="FunCalc is a fun, web-based scientific calculator built with Spring Boot and Thymeleaf. It offers a range of scientific
               functions, lively animations, and jokes to make calculations more enjoyable. Perfect for both serious study and casual exploration of math."
              ghlink="https://github.com/Aryesh404/FunCalc_An-animated-scientifiic-calculator"
              // deployedLink="https://funny-calculator-25d57ab7885c.herokuapp.com/" // Add your deployed link here
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={EmployeeManagement}
              isBlog={false}
              title="Employee Management System"
              description="Developed an Employee Management System using JSP, Servlet, and SQL technologies, providing employees with secure access to their personal information upon login while enabling administrators to manage employee data efficiently."
              ghlink="https://github.com/Aryesh404/Employee-Management-System"
              // Add your deployed link here
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={WeatherWebApp}
              isBlog={false}
              title="Weather Web App"
              description="Designed and developed a dynamic Weather web application using Spring Boot and Thymeleaf, integrating the OpenWeather API for real-time weather tracking. This project provided users with up-to-date weather information based on their location input, enhancing the overall user experience and engagement."
              ghlink="https://github.com/Aryesh404/Weather-Web-App-Updated"
              // deployedLink="https://weather-web-app-ec49d5fb2448.herokuapp.com/"
              // Add your deployed link here
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={CurrencyConverter}
              isBlog={false}
              title="Currency Converter"
              description="Currency Converter is a web application built with HTML, CSS, and JavaScript, providing users with an intuitive interface to convert between different currencies using real-time exchange rates. With support for a wide range of currencies and a responsive design, it offers a seamless experience for users across various devices."
              ghlink="https://github.com/Aryesh404/Currency-Converter"
              deployedLink="https://convertmycoins.netlify.app/"
              // Add your deployed link here
            />
          </Col>
          
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
