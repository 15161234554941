import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  const formUrl = 'https://api.web3forms.com/submit';
  const accessKey = '6c55bc0d-0c09-49b1-af20-fe2c502d0a6d';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage('Submitting...');

    try {
      const response = await fetch(formUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          access_key: accessKey,
          ...formData
        })
      });

      if (response.ok) {
        setResponseMessage('Form submitted successfully!');
        setShowPopup(true);
        setFormData({ name: '', mobile: '', email: '', message: '' });
      } else {
        setResponseMessage('There was an error submitting the form. Please try again.');
      }
    } catch (error) {
      setResponseMessage('An error occurred. Please check your connection and try again.');
    }
  };

  const closePopup = () => setShowPopup(false);

  return (
    <div style={formStyles}>
      <h2 style={headingStyles}>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <div style={inputContainer}>
          <label htmlFor="name" style={labelStyles}>
            Name<span style={{ color: 'red' }}> *</span>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            style={inputStyles}
            required
          />
        </div>
        <div style={inputContainer}>
          <label htmlFor="mobile" style={labelStyles}>
            Mobile Number<span style={{ color: 'red' }}> *</span>
          </label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            style={inputStyles}
            required
          />
        </div>
        <div style={inputContainer}>
          <label htmlFor="email" style={labelStyles}>Email (Optional)</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            style={inputStyles}
          />
        </div>
        <div style={inputContainer}>
          <label htmlFor="message" style={labelStyles}>Message (Optional)</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            style={{ ...inputStyles, height: '80px' }}
          />
        </div>
        <button type="submit" style={buttonStyles}>Submit</button>
        {responseMessage && <p style={{ marginTop: '10px', color: 'green' }}>{responseMessage}</p>}
      </form>

      {showPopup && (
        <div style={popupOverlayStyles}>
          <div style={popupStyles}>
            <h3>Thank You!</h3>
            <p>Your form was submitted successfully.</p>
            <button onClick={closePopup} style={closeButtonStyles}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

const formStyles = { maxWidth: '350px', margin: '150px auto', padding: '30px', backgroundColor: '#2c2c34', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', color: '#fff' };
const headingStyles = { fontSize: '24px', color: '#FFA500', textAlign: 'center', marginBottom: '16px', fontWeight: 'bold' };
const inputContainer = { marginBottom: '12px' };
const labelStyles = { marginBottom: '6px', fontSize: '14px', color: '#fff', textAlign: 'left', width: '100%' };
const inputStyles = { width: '100%', padding: '8px', fontSize: '14px', borderRadius: '4px', border: '1px solid #ddd', backgroundColor: '#fff', color: '#000' };
const buttonStyles = { width: '100%', padding: '10px', fontSize: '14px', backgroundColor: '#FFA500', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' };
const popupOverlayStyles = { position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000 };
const popupStyles = { width: '250px', padding: '16px', backgroundColor: '#fff', borderRadius: '8px', textAlign: 'center', color: '#000' };
const closeButtonStyles = { marginTop: '12px', padding: '8px 16px', backgroundColor: '#FFA500', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' };

export default ContactForm;
